$primary-color: #FB0;
$image-border: #999;
$background-gray: #F8F8F8;
$header-font-family: 'oswald', sans-serif;
$font-family: 'Open Sans', sans-serif;
$font-color: #333;
$light-border-color: #EEE;

body {
  color: $font-color;
  font-family: $font-family;
  font-weight: 400;
  font-display: auto;
}

a,p,ul,li,h1,h2,h3,h4,h5,h6,span,div {
	user-select: none;
}

a { color: $font-color;}
a:hover { cursor: pointer; color: $primary-color; text-decoration: none; }

header {
  border-top: 10px solid $primary-color;
  background-color: $background-gray;
  padding: 20px 0;
  border-bottom: 1px solid $light-border-color;

  h1 {
    font-family: $header-font-family;
    letter-spacing: .05em;
    text-transform: uppercase;
    margin-top: 35px;
  }

  h1, h5, a {
    color: $font-color;
  }

  a:hover {
    color: $primary-color;
    text-decoration: none;
  }

  .social-media i {
    border-radius: 2px;
    margin: 5px 5px 0 0;
    opacity: .7;

    &:hover {
      opacity: 1;
    }
  }

  .resume-image {
    width: 160px;
    height: 160px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    overflow: hidden;
    margin: 0 auto 10px;
    border: 2px dashed $image-border;
    background-size: 160px 160px;
    background-repeat: no-repeat;
    display: block;
  }

  .resume-image:hover {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  .numero {
    letter-spacing: .05em;
  }
}
.meat {
  .section {
    margin: 20px 0;
  }

  .section-title h2 {
    text-align: left;
    font-family: $header-font-family;
    color: $font-color;
    letter-spacing: .1em;
    padding-top: 5px;
    text-transform: uppercase;
    font-size: 18px;
    margin: 0;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
  }
  ul > li:before {
    display: inline-block;
    content: ">";
    width: 1em;
    margin-left: -1em;
    font-size: 12px;
    color: #999;
  }

  p, ul {
    margin-top: 10px;
  }

  .label {
    margin-top: 5px;
  }

  .skills {
    display: inline;
  }

  .skills li {
    float: left;
  }

  .skills li:before {
    display: inline-block;
    content: "";
    margin-left: -10px;
  }

  .employment-details {
    position: relative;
    clear: both;
    display: block;
    overflow: hidden;
  }

  .employer-wrapper {
    overflow: hidden;
  }

  .employer {
    float: left;
  }

  .employer h3 {
    font-size: 17.5px;
    margin: 0;
    font-weight: bolder;
  }

  .employer h4 {
    margin: 0;
    font-size: 14.5px;
  }

  .dates {
    background: #e6e6e6;
    padding: 3px 5px;
    border-radius: 5px;
    position: relative;
    float: right;
    margin: 12px 5px 0 0;
  }

  .sole {
    font-size: 15px;
  }
}

footer {
	background-color: $background-gray;
	border-top: 1px solid $light-border-color;
	margin: 50px auto 0;
	padding:20px;
	color: $font-color;
    font-weight: 300;
	text-align: right;
	border-bottom: 10px solid $primary-color;
}


@media (max-width: 767px) {

	body { padding: 0; }

	.meat, .top-tier {
		padding-right: 20px;
		padding-left: 20px;
	}

	.meat .section-title {
		text-align: left !important;
		padding-right: 0;
	}

	.contact-info {
		text-align: center;
	}

	.dates {
		clear: both;
		float: left !important;
	}

}

@media (min-width: 1200px) {
	.container {
		width: 980px;
	}

}

.meat, footer, header {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
